import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MemberService } from '../services/member/member.service';
import { environment } from 'src/environments/environment';
import { CustomValidatorService } from '../services/custom-validator/custom-validator.service';
import { AppInsightsService } from '../services/app-insights/app-insights.service';
import { AppCenterAnalytics } from '../services/app-insights/app-insights';
import { MultilingualService } from '../core/services/multilingual/multilingual.service';

@Component({
  selector: 'app-forgot-username',
  templateUrl: './forgot-username.component.html',
  styleUrls: ['./forgot-username.component.scss']
})
export class ForgotUsernameComponent implements OnInit {

  @ViewChild('success', { read: TemplateRef }) successTemplate!: TemplateRef<any>;
  @ViewChild('error', { read: TemplateRef }) errorTemplate!: TemplateRef<any>;

  email!: string;
  isError = false;
  isSuccess = false;
  isLoading = false;
  invalidEmail = false;
  hideOkButton = false;

  constructor(
    private readonly memberService: MemberService,
    private readonly appInsightsService: AppInsightsService,
    private readonly route: ActivatedRoute,
    public readonly multilingualService: MultilingualService,
  ) { }

  ngOnInit(): void {
    this.appInsightsService.trackPageViewWithoutClientId(AppCenterAnalytics.FORGOT_USERNAME_PAGE_VIEWED);

    this.route.queryParams
      .subscribe(params => {
        if (params && params.redirect) {
          this.hideOkButton = JSON.parse(params.redirect);
        }
      }
      );
    this.setHeaderUIForIOSScreens();
  }

  private setHeaderUIForIOSScreens(): void {
    try {
      // for IOS apps, set padding for top header and elements
      if (navigator.userAgent &&
        navigator.userAgent.includes("com.empirx.health") &&
        navigator.userAgent.includes("ios")) {
        document.getElementsByTagName("html")[0].style.paddingTop = "40px";
        const headerSection = document.getElementById("header_section");
        if (headerSection) {
          headerSection.style.minHeight = "105px";
        }
        const backIcon = document.getElementById("back_icon_mob");
        if (backIcon) {
          backIcon.style.paddingTop = "48px";
        }
      }
    } catch (error) {
      console.log('Failed to update viewport content and header UI', error);
    }
  }

  isEmailValid(email: string): boolean {
    return CustomValidatorService.emailRegEx.test(email);
  }

  clearError(): void {
    this.invalidEmail = false;
  }

  sendMail(): void {
    if (!this.isEmailValid(this.email || '')) {
      this.invalidEmail = true;
      return;
    }
    this.isLoading = true;
    this.isError = false;
    this.isSuccess = false;
    this.memberService.sendMail(this.email).subscribe((res) => {
      this.appInsightsService.trackPageViewWithEmailProperties(AppCenterAnalytics.USERNAME_SENT_VIA_EMAIL, this.email, false);
      this.isLoading = false;
      this.isSuccess = true;
    }, () => {
      this.appInsightsService.trackPageViewWithEmailProperties(AppCenterAnalytics.FORGOT_USERNAME_ERROR, this.email, true, 'This email is not the registered email in your profile. Please contact the customer support at 866-415-0222 or 857-241-2439 or try again with your registered email address.');
      this.isLoading = false;
      this.isError = true;
    });
  }

  navigateBack(event: boolean): void {
    if (event) {
      this.appInsightsService.trackPageViewWithEmailProperties(AppCenterAnalytics.FORGOT_USERNAME_OK_CLICKED, this.email, false);
    }
    window.location.replace(environment.b2cSignUpSignInFlow);
  }
}
