/**
 * @file auth.service.ts
 */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '../token/token.service';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private readonly router: Router,
    private readonly tokenService: TokenService,
    private readonly httpClient: HttpClient
  ) { }

  public login(idToken: string, ssoToken: string, providerType?: string): void {
    this.tokenService.setAzureIdToken(idToken);
    this.tokenService.setSSOToken(ssoToken);
    this.tokenService.setProviderType(providerType);
    this.router.navigate(['/main']);
  }

  public loginWithBenecard(accessToken: string): void {
    this.tokenService.setBenecardAccessToken(accessToken);
    this.router.navigate(['/main']);
  }

  public logout(): void {
    localStorage.clear();
    window.location.replace(environment.b2cSignUpSignInFlow);
  }

  public getProviderTypeByClientId(clientId: string): Observable<string> {
    return this.httpClient.get<{ platform: string }>(`${environment.hostURL}/routing/platform?clientId=${clientId}`).pipe(map((res) => res.platform));
  }

  get isAuthorizedByAzureB2c(): boolean {
    return !!this.tokenService.azureIdToken;
  }

  get isAuthorizedBySSO(): boolean {
    return !!this.tokenService.ssoToken;
  }

  get isAuthorizedByBenecard(): boolean {
    return !!this.tokenService.benecardAccessToken;
  }

}
