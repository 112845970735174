/**
 * @file toastr.component.ts
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-toastr',
  templateUrl: './toastr.component.html',
  styleUrls: []
})
export class ToastrComponent implements OnInit {

  public title!: string;
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data?: {title: string}) { }

  ngOnInit(): void {
    if(this.data) {
      this.title = this.data.title;
    }
  }

}
