/**
 * @file token.interceptor.ts
 */
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIConstants } from 'src/app/services/services.constants';

@Injectable()
export class APIsWithNoProvider implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes(`${environment.hostURL}${APIConstants.MEMBER_SERVICES.SEND_EMAIL}`)) {
      request = request.clone({ headers: request.headers.delete('ProviderType') });
    }
    return next.handle(request);
  }
}
