/**
 * @file error.interceptor.ts
 * @description this file implements interceptor to handler http errors
 */
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { ErrorManagerService, ErrorType } from '../../core/services/error';
import { ErrorHandlerService } from '../../services/error-handler/error-handler.service';
import { AuthService } from '../../core/services/auth/auth.service';
import { MultilingualService } from '../../core/services/multilingual/multilingual.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  private static readonly className = 'ErrorInterceptor';
  constructor(
    private readonly errorManager: ErrorManagerService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly authService: AuthService,
    private readonly multilingualService: MultilingualService
  ) { }

  /**
   * @description Interceptor to handle http errors
   * @param  HttpRequest request
   * @param  HttpHandler next
   * @returns Observable
   */
  public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    let errMsg: any;
    return next.handle(request).pipe(
      // retry(2),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.authService.logout();
        } else if (error.status === 500 || error.error instanceof Blob) {
          errMsg = this.multilingualService.langMap.ERROR_COMMON_SOMETHING_WENT_WRONG;
        } else if (typeof (error.error) === 'string' && error.error.includes('{')) {
          errMsg = JSON.parse(error.error).message;
        } else if (typeof (error.error) === 'string') {
          errMsg = error.error;
        } else if (typeof (error.error) === 'object') {
          errMsg = error.error && error.error.message ? error.error.message : this.multilingualService.langMap.ERROR_COMMON_SOMETHING_WENT_WRONG;
        } else {
          errMsg = this.multilingualService.langMap.ERROR_COMMON_SOMETHING_WENT_WRONG;
        }
        // else {
        //    errMsg = error.error.message;
        //   // this.errorManager.throwError({
        //   //   type: ErrorType.ERROR_GENERIC,
        //   //   message: error.statusText,
        //   //   component: ErrorInterceptor.className,
        //   //   title: ''
        //   // });
        // }
        return throwError(errMsg);
      })
    );
  }
}
