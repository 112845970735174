<div class="center loader-circle" *ngIf="!loaded">
  <img src="../../../../assets/images/loader.gif" alt="loader" width="60px" />
</div>
<div class="main" *ngIf="loaded">
  <div class="custom-wrapper">
    <div class="center logo">
      <img src="../../assets/images/logo.svg" alt="Logo" width="95" />
    </div>
    <div class="login-content">
      <div class="login-content-wrapper">
      <h2>Registration</h2>
      <div class="form-content">
        <form [formGroup]="userForm">
          <div class="form-group mb-2">
            <label>Email <span class="text-primary text-w-600">*</span></label>
            <input formControlName="email" type="text" class="form-control"/>
            <span *ngIf="this.userForm.get('email')?.errors?.required && this.userForm.get('email')?.touched"
              class="text-danger">Please enter email</span>
            <span *ngIf="this.userForm.get('email')?.errors?.pattern && this.userForm.get('email')?.touched"
              class="text-danger">Please enter valid email</span>
          </div>
          <!-- <div class="form-group mb-2">
            <label>Mobile Number <span class="text-primary text-w-600">*</span></label>
            <input formControlName="mobileNumber" type="text" class="form-control"/>
            <span *ngIf="this.userForm.get('mobileNumber')?.errors?.required && this.userForm.get('mobileNumber')?.touched"
            class="text-danger">Please enter mobile number</span>
            <span *ngIf="(this.userForm.get('mobileNumber')?.errors?.minlength || this.userForm.get('mobileNumber')?.errors?.maxlength) && this.userForm.get('mobileNumber')?.touched"
            class="text-danger">Mobile number should be 10 digits</span>
          </div> -->
          <div class="form-group mb-2">
            <label>Contact Number <span class="text-primary text-w-600">*</span>
            </label>
            <input formControlName="contactNumber" type="text" class="form-control" />
            <span
              *ngIf="this.userForm.get('contactNumber')?.errors?.required && this.userForm.get('contactNumber')?.touched"
              class="text-danger">Please enter contact number</span>
            <span
              *ngIf="(this.userForm.get('contactNumber')?.errors?.minlength || this.userForm.get('contactNumber')?.errors?.maxlength) && this.userForm.get('contactNumber')?.touched"
              class="text-danger">Contact number should be 10 digits</span>
          </div>
          <div class="form-group mb-2">
            <label>First Name</label>
            <p class="text-w-700">{{this.userForm.get('firstName')?.value}}</p>
          </div>
          <div class="form-group mb-2">
            <label>Last Name</label>
            <p class="text-w-700">{{this.userForm.get('lastName')?.value}}</p>
          </div>
          <div class="form-group mb-2">
            <label>Date of Birth (MM-DD-YYYY)</label>
            <p class="text-w-700">{{this.userForm.get('dateOfBirth')?.value | date:'MM-dd-yyy'}}</p>
          </div>
          <div class="form-group mb-2">
            <label>Card ID</label>
            <p class="text-w-700">{{this.userForm.get('cardId')?.value}}</p>
          </div>
          <div class="form-group mb-2">
            <label>Rx GRP</label>
            <p class="text-w-700">{{this.userForm.get('clientId')?.value}}</p>
          </div>
        </form>
      </div>
      <div class="buttons center mb-2">
        <img *ngIf="isLoading" class="loader" src="../../../../assets/images/loader.gif" alt="loader" width="60px" />
        <button *ngIf="!isLoading" [disabled]="userForm.invalid" (click)="complete()" mat-raised-button type="submit"
          class="primary-button uppercase">Register</button>
      </div>
      </div>
    </div>
  </div>
</div>
<ng-template #error>
  <div class="toaster-bg error d-flex justify-content-center">
    <mat-icon class="icon-error mr-1"></mat-icon>
    <span>{{errorMsg}}</span>
  </div>
</ng-template>