import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ProfilerInterceptor } from './profiler/profiler.interceptor';
import { ErrorInterceptor } from './error/error.interceptor';
import { TokenInterceptor } from './token/token.interceptor';
import { WordpressInterceptor } from './wordpress/token/wordpress.interceptor';
import { APIsWithNoProvider } from './username/no-provider';
import { NoAuthorizationInterceptor } from './delete-member/no-authorization.interceptor';

export const HttpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ProfilerInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: WordpressInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: APIsWithNoProvider, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: NoAuthorizationInterceptor, multi: true },
  
];
