/**
 * @file token.interceptor.ts
 */
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService } from '../../core/services/token/token.service';
import { environment } from 'src/environments/environment';
import { AppConstants } from 'src/app/app.constants';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private readonly tokenService: TokenService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const providerType = this.tokenService.getProviderType();

    request = request.clone({
      setHeaders: {
        Authorization: this.tokenService.azureIdToken || '',
        'Authorization-SSO': this.tokenService.ssoToken || '',
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'ProviderType': providerType ? providerType : AppConstants.GUEST_PROVIDER_TYPE
      }
    });
    return next.handle(request);
  }
}
