/**
 * @file auth.guard.ts
 */
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, defer, of } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { environment } from '../../../../environments/environment';
import { MultilingualService } from '../../services/multilingual/multilingual.service';
import { TokenService } from '../../services/token/token.service';
import { ToastrComponent } from '../../components/toastr/toastr.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AzureB2CAuthGuard implements CanActivate {

  constructor(
    private readonly authService: AuthService,
    private readonly multilingualService: MultilingualService,
    private readonly tokenService: TokenService,
    private readonly snackBar: MatSnackBar,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const fragment = route.fragment!;
    return defer(() => {
      if (fragment) {
        const idToken = new URLSearchParams(fragment).get('id_token') || '';
        const ssoToken = new URLSearchParams(fragment).get('sso_token') || '';
        const errorDescription = new URLSearchParams(fragment).get('error_description');

        return defer(() => {
          if (idToken || ssoToken) {
            if (idToken) {
              // check if logged in from forgot password flow
              if (this.tokenService.isForgotPasswordLogin(idToken)) {
                this.snackBar.openFromComponent(ToastrComponent, {
                  data: {title: 'Password reset successful. Please re-login with your new password.'},
                  duration: 2000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center'
                });
                setTimeout(() => {
                  // Redirect users to login screen after successful password reset
                  this.login();
                }, 2000);
                return of(false);
              }
            }
            this.setToken(idToken, ssoToken);
            return of(true);
          } else {
            if (errorDescription?.split(':').includes('AADB2C90118')) {
              // user forgot password flow
              this.resetPass();
            } else {
              this.login();
            }
            return of(false);
          }
        })
      } else {
        return defer(() => {
          if (this.authService.isAuthorizedByAzureB2c || this.authService.isAuthorizedBySSO) {
            this.multilingualService.getLangMap(this.multilingualService.getUserLanguage(), true);
            return of(true);
          } else {
            this.login();
            return of(false);
          }
        });
      }
    });
  }

  public setToken(idToken: string, ssoToken: string, providerType?: string) {
    // handle token
    this.authService.login(idToken, ssoToken, providerType);
    this.multilingualService.getLangMap(this.multilingualService.getUserLanguage(), true);
  }

  public resetPass(): void {
    window.location.replace(environment.b2cForgotPassFlow);
  }

  public login(): void {
    //this.appInsightsService.trackPageView(AppCenterAnalytics.LOGIN_FORM_VIEWED);
    window.location.replace(environment.b2cSignUpSignInFlow);
  }
}
