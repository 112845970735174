/**
 * @file app.component.ts
 * @description a root app component
 */
import { Component, Inject, OnInit } from '@angular/core';

import { LogService } from './core/services/log/log.service';
import { environment } from 'src/environments/environment';
import { MultilingualService } from './core/services/multilingual/multilingual.service';
import { AppInsightsService } from './services/app-insights/app-insights.service';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { LoaderService } from './core/services/loader/loader.service';
import { AppConstants } from './app.constants';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  private static readonly className = 'AppComponent';

  /**
   * @param  LogService logService
   */
  constructor(
    private readonly logService: LogService,
    private readonly multilingualService: MultilingualService,
    private readonly loaderService: LoaderService,
    private readonly appInsightsService: AppInsightsService,
    private readonly router: Router,
    @Inject(DOCUMENT) private doc: Document
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.loaderService.show();
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loaderService.hide();
      }
    });
  }

  public ngOnInit(): void {
    console.log('Environment: ' + environment.envName);
    this.setLog();
    // if (this.doc.location.origin !== environment.newUrl) {
    //   this.doc.location.href = environment.newUrl + document.location.pathname;
    // }
    // setTimeout(() => {
    //   this.multilingualService.getLangMap(AppConstants.LANGUAGE.DEFAULT, true);
    // }, 1000);
  }


  /**
   * @description Enable logging in dev env only
   */
  private setLog(): void {
    if (!environment.production) {
      // this.logService.enableLogging();
    }
  }

}
