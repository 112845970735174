/**
 * @file multilingual.service.ts
 * @description this file handles functionality for multilingual support
 */
import { Injectable } from '@angular/core';
import { LangMap } from 'src/app/interfaces';
import { AppConstants } from 'src/app/app.constants';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { ErrorManagerService } from '../error';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../loader/loader.service';
import { labels } from 'src/assets/doc/labels';
import { ErrorHandlerService } from '../../../services/error-handler/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class MultilingualService {
  private static readonly className = 'MultilingualService';
  public readonly langMapObservable: Subject<LangMap> = new Subject<LangMap>();
  public readonly langMapFailureObservable: Subject<void> = new Subject<void>();
  public langMap!: LangMap;
  public selectedLang: string = AppConstants.LANGUAGE.DEFAULT;
  public selectedTextSize: string = AppConstants.TEXT_SIZE.SMALL;

  constructor(
    private readonly http: HttpClient,
    private readonly errorManager: ErrorManagerService,
    private readonly errorHandler: ErrorHandlerService,
    private readonly loaderService: LoaderService
  ) {
    // this.getLangMap(this.getUserLanguage(), true);
  }

  /**
   * get current users language
   * @returns string
   */
  public getUserLanguage(): string {
    // return current user language
    return localStorage.getItem('lang') || AppConstants.LANGUAGE.DEFAULT;
  }

  /**
   * @description Get particular language mapper json object
   * @param  string lang
   * @returns void
   */
  public getLangMap(lang: string = AppConstants.LANGUAGE.DEFAULT, isFirstTime: boolean = false): void {
    if (!isFirstTime) {
      this.loaderService.show();
    }

    const headers = new Headers();
    // headers.append('Authorization', 'Basic ' + btoa(environment.langHostUserName + ':' + environment.langHostPassword));
    // headers.append('Authorization', 'Basic ' + btoa(environment.langHostUserName + ':' + environment.langHostPassword));
    // headers.append('Content-Type', 'application/x-www-form-urlencoded');
    this.http.get<LangMap>(
      `${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/resource-file/${lang}`,
    ).subscribe((langMap: LangMap) => {
      localStorage.setItem('lang', lang);
      this.selectedLang = lang;

      //check for missing labels
      try {
        if (langMap) {
          this.langMap = this.fillMissingLabels(langMap, labels);
        } else {
          this.langMap = labels; // use local labels if translations are missing from WP API
        }
      } catch (error) {
        console.log('MultilingualService.getLangMap(): Error while filling missing labels: ', error);
        this.langMap = langMap;
      }

      this.langMapObservable.next(this.langMap);
      this.loaderService.hide();

    }, (error: HttpErrorResponse) => {
      this.errorHandler.closeErrorDialog();
      this.selectedLang = AppConstants.LANGUAGE.DEFAULT;
      this.langMap = labels;
      this.langMapObservable.next(this.langMap);
      this.loaderService.hide();
      this.langMapFailureObservable.next();
    });
  }

  /**
   * @returns Subject
   */
  public getLangMapObservable(): Subject<LangMap> {
    return this.langMapObservable;
  }

  private fillMissingLabels(langMap: LangMap, labels: any): LangMap {
    if (langMap) {
      const localLabels = Object.keys(labels);
      for (let labelKey of localLabels) {
        if (!langMap[labelKey]) {
          langMap[labelKey] = (labels as any)[labelKey];
        }
      }
    }
    return langMap;
  }

  public getTextSizeValue(): string {
    return this.selectedTextSize;
  }

}
