<div class="main">
  <div class="custom-wrapper">
    <div class="center logo">
      <img src="../../assets/images/emprix-logo.svg" alt="Logo" width="130px"/>
    </div>
    <div class="login-content">
      <h2>Login</h2>
      <form>
        <div class="form-content">
          <div class="form-group mb-2">
            <label for="email">Registered Email </label>
            <input type="text" placeholder="" value="" name="Registered Email" class="form-control"/>
          </div>
          <div class="form-group">
            <label for="password">Password </label>
            <input type="password" id="password" placeholder="" value="" name="Password" class="form-control"/>
          </div>
          <a (click)="register()" class="pointer d-block">Forgot Password?</a>
          </div>
          <div class="buttons center">
            <button (click)="login()" mat-raised-button  type="submit" class="primary-button uppercase">Login</button>
            <p> Not Registered? <a class="pointer" (click)="register()">Register here</a> </p>
          </div>
      </form>
    </div>
  </div>
</div>
