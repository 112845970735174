/**
 * @file environnement.staging.ts
 */
export const environment = {
  envName: 'staging',
  production: false,
  hostURL: 'https://api-uat.myempirxhealth.com/api',
  langHostURL: `https://api-uat.myempirxhealth.com/api`,
  b2cSignUpSignInFlow: 'https://empirxmpb2cuat.b2clogin.com/empirxmpb2cuat.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignUpSignIn&client_id=e734246b-89a9-431b-8753-ec4f3da1aee2&nonce=defaultNonce&redirect_uri=' + window.location.origin + '&scope=openid&response_type=id_token&prompt=login',
  // b2cSignUpSignInFlow: 'https://empirxmpb2cuat.b2clogin.com/empirxmpb2cuat.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignUpSignIn&client_id=e734246b-89a9-431b-8753-ec4f3da1aee2&nonce=defaultNonce&redirect_uri=' + window.location.origin + '&scope=openid&response_type=id_token&prompt=login',
  b2cForgotPassFlow: 'https://empirxmpb2cuat.b2clogin.com/empirxmpb2cuat.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_PasswordReset&client_id=e734246b-89a9-431b-8753-ec4f3da1aee2&nonce=defaultNonce&redirect_uri=' + window.location.origin + '&scope=openid&response_type=id_token&prompt=login',
  // b2cForgotPassFlow: 'https://empirxmpb2cuat.b2clogin.com/empirxmpb2cuat.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_PasswordReset&client_id=e734246b-89a9-431b-8753-ec4f3da1aee2&nonce=defaultNonce&redirect_uri=' + window.location.origin + '&scope=openid&response_type=id_token&prompt=login',
  googleApiKey: 'AIzaSyBfo9a3aDoFj_C9TyCowJa2g7Dq7pVpkSI',
  instrumentationKey: '53a7fb12-11df-4d2d-92b2-a25187f54de5',
  getDocuments: 'https://api-uat.myempirxhealth.com/api/CMSDocker/index.php/wp-json/wp/v2/pages/pdf-download',
  newUrl: 'https://www.myempirxhealthuat.com',
  wpApiToken: '0zflbf70n6vrz5runxcmiwhotvygahyu3rza7nhq',
  ocpApimSubscriptionKey: '21adba1d19e34869b85892e3707483ce'
};
