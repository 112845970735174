<section class="custom-page-width auto-margin">
  <div class="header-inner">
    <div class="static-brand">
      <a
        href="https://www.empirxhealth.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="../../assets/images/logo.svg" width="95" alt="EmpiRx" class="brand"
      /></a>
    </div>
    <h3 class="static-title">Privacy Policy</h3>
  </div>
  <div class="custom-box padding-box mb-3 d-inline-block full-width">
    <h4 class="text-tertiary text-w-700 uppercase title-10">
      EMPIRX HEALTH PRIVACY STATEMENT
    </h4>
    <p>
      EmpiRx Health is committed to respecting the privacy and security of your
      information. By using the EmpiRx Health website (the “Website”) and EmpiRx
      Health member portal (the “Portal”), you are agreeing to our Privacy
      Statement. We may update and change this Statement from time to time
      without notice to you, and by using or accessing the Website and Portal
      you are agreeing to accept those changes.
    </p>
    <p _ngcontent-ers-c59="">
      Our use and disclosure of your personal health information (PHI) is
      governed by the Health Insurance Portability and Accountability Act
      (commonly known as “HIPAA”), a Business Associate agreement between us and
      the Health Plan and your Health Plan’s Notice of Privacy Practices, which
      you may obtain from your Health Plan. If you have any questions about
      which Statement applies to information you submit to us, please do not
      hesitate to contact us via email at:
      <a _ngcontent-ers-c59="" href="mailto:privacy@empirxhealth.com"
        >privacy@empirxhealth.com</a
      >
      or mail at: EmpiRx Health, LLC, ATTN: Privacy Officer, 155 Chestnut Ridge
      Road, Montvale, NJ 07645.
    </p>
    <p _ngcontent-ers-c59="">
      We maintain security measures as required under HIPAA and the Health
      Information Technology for Economic Clinical Health Act (“HITECH”) to help
      protect against the loss, misuse, and alteration of the information under
      our control. Our web server uses encryption technology to ensure private
      and authenticated communication by encrypting all user-identifying data
      between two parties (user and Website/Portal).
    </p>
    <p _ngcontent-ers-c59="">
      <strong _ngcontent-ers-c59="">Security Measures</strong> We take certain
      measures to enhance the security of our service, specifically: (i) the
      service is protected by multiple firewalls; (ii) we have installed
      functions capable of detecting and thwarting cyber-attacks; (iii) the
      service is protected by SSL certificates that encrypt all ingress and
      egress traffic; (iv) we have taken steps to allow only the intended
      traffic to traverse between the servers hosting the application; (v) no
      PHI is stored within our mobile application; and (vi) periodic
      vulnerability assessment and penetration testing is carried out on the
      service. Notwithstanding the foregoing, we make no representations as to
      the security or privacy of your information outside of the specific
      industry-standard mechanisms we have undertaken in good faith.
    </p>
    <p _ngcontent-ers-c59="">
      <strong _ngcontent-ers-c59="">Information Collected </strong> We may
      collect and store certain personal information from you when you register
      through the Portal that can be used to identify you, such as your name,
      address, phone number, date of birth, member ID, email address, and/or
      other information that we use to establish your profile. We also collect
      and maintain health information that we receive from your Health Plan,
      pharmacies, your physicians, and you in order to provide the services. The
      information we collect from you to the extent that it is private, is
      disclosed only in accordance with this Privacy Statement.
    </p>
    <p _ngcontent-ers-c59="">
      <strong _ngcontent-ers-c59="">De-Identified Information.</strong> Whenever
      you use our services, we also may collect non-identifying information from
      you, such as your IP address, referring URL, operating system, cookie
      information, and Internet Service Provider. We do not identify you by
      individual name, email address, home address, or telephone number. We use
      this data in the aggregate to evaluate use of the Website and Portal to
      troubleshoot and improve the Website and Portal, among other business
      purposes. We may also provide this statistical information to
      third-parties.
    </p>
    <p _ngcontent-ers-c59="">
      <strong _ngcontent-ers-c59="">Personal Information.</strong> We may use or
      disclose your personal identified health information as allowed by your
      Health Plan and as permitted as a Business Associate under HIPAA to
      process your prescription drug claims and copayments, provide you
      formulary and benefit plan information, and perform clinical programs
      authorized by your Health Plan. We may send emails or texts to you about
      the services or respond to inquiries. There are instances in which we may
      disclose your personal information that you voluntarily submitted
      internally with our agents, contractors, and affiliates to enable them to
      perform certain functions for us. We may use your personal identifying
      information to statistically analyze Website usage, improve content and
      product offerings, and customize the Website and Portal content, layout,
      and services.
    </p>
    <p _ngcontent-ers-c59="">
      We may use your email address, mailing address, and phone number to
      contact you regarding administrative notices, new benefit offerings, and
      communications relevant to your use of the Website and Portal. We may also
      use or disclose information to resolve disputes, investigate problems, or
      enforce the Terms of Use. We may disclose or access information whenever
      we believe in good faith that the law so requires or if we otherwise
      consider it necessary to do so to maintain and improve the services.
    </p>
    <p _ngcontent-ers-c59="">
      Our communications to you may include messages sent through the use of
      email, landline phone and/or cellular phone. By submitting your personal
      information, including your communication preferences (e.g., phone number,
      email address, or other contact information), you are providing your
      express written consent to receive communications from us at the email
      address and telephone numbers you provide to us. The transmission of
      information via the internet is not completely secure. Although we do our
      best to protect your personal information, we cannot guarantee the
      security of your personal information transmitted to us via email. Any
      transmission of personal information is at your own risk. We are not
      responsible for circumvention of any privacy settings or security measures
      contained on the Website.
    </p>
    <p _ngcontent-ers-c59="">
      <strong _ngcontent-ers-c59="">Cookies.</strong> Cookies are small data
      files that are placed on your computer or other devices by websites that
      you visit. We may use cookies for various purposes including, but not
      limited to, remembering your preferences, maintaining User sessions,
      improving security of our services, and collecting usage information. You
      can set your browser to notify you when you receive a cookie, giving you
      the chance to decide whether to accept it. You may also delete a cookie
      manually from your hard drive through your internet browser or other
      programs. Because the Website makes use of cookies, please note that some
      parts of the Website may not function properly or be available to you if
      you refuse to accept a cookie or choose to disable the acceptance of
      cookies.
    </p>
    <p _ngcontent-ers-c59="">
      At this time, we do not honor “do not track” signals from a web site
      browser. However, you may refuse or delete cookies. If you have your
      browser set to private browsing, or refuse or delete cookies, you may not
      be able to take full advantage of the Website and Portal.
    </p>
    <p _ngcontent-ers-c59="">
      <strong _ngcontent-ers-c59=""
        >Third Party Access to Your Information.</strong
      >
      We use third party organizations to assist us in providing the services
      and establishing and maintaining the Website and Portal, including
      contractors, web hosting companies, and others. Throughout the course of
      our provision of our services to you, we may share your information with
      such third parties who may have authority to collect, access, use, and
      disseminate your information in accordance with the Terms of Use and this
      Privacy Statement. Without limiting the generality of the foregoing, you
      authorize us to collect, share, store, exchange, and otherwise use your
      information in conjunction with the Service.
    </p>
    <p _ngcontent-ers-c59="">
      <strong _ngcontent-ers-c59=""
        >Release of Your Information for Legal Purposes</strong
      >
      At times, it may become necessary to release your information in response
      to a request from a government agency or in connection with a lawsuit or
      other legal action. You agree that we may disclose your information to a
      third party where we believe, in good faith, that it is appropriate to do
      so for the purposes of a civil action, criminal investigation, or other
      legal matter. We may also proactively report you, and release your
      information to, third parties where we believe that it is prudent to do so
      for legal reasons, such as our belief that you have engaged in fraudulent
      activities. You release us from any damages that may arise from or relate
      to the release of your information to a request from law enforcement
      agencies or connection with a lawsuit or other legal matter.
    </p>
  </div>
</section>
