import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIConstants } from 'src/app/services/services.constants';

@Injectable()
export class NoAuthorizationInterceptor implements HttpInterceptor {

  constructor() {}
 // Delete-member url will launch as external link(without login) so need to remove authorization  
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
      if (request.url.includes(`${environment.hostURL}${APIConstants.DELETE_MEMBER_ACCOUNT.REQUEST_OTP}`) || 
          request.url.includes(`${environment.hostURL}${APIConstants.DELETE_MEMBER_ACCOUNT.VERIFY_OTP}`) ||
          request.url.includes(`${environment.hostURL}${APIConstants.DELETE_MEMBER_ACCOUNT.DELETE_ACCOUNT}`)) {
        request = request.clone({ 
          headers: request.headers.delete('Authorization').delete('Authorization-SSO')
        });
      }
      return next.handle(request);
    }
  }

