/**
 * @file complete-profile.service.ts
 */
import { Injectable } from '@angular/core';
import { User } from '../interfaces/user.interface';
import { RequestRegisterSso } from '../interfaces/request/sso-register.interface';
import { Observable, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIConstants } from '../services/services.constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CompleteProfileService {

  ssoToken = '';
  user!: User;

  constructor(private http: HttpClient) { }

  public registerSsoMember(ssoRegisterRequest: RequestRegisterSso): Observable<any> {
    let apiBaseUrl: string = environment.hostURL;
    return this.http.post(`${apiBaseUrl}${APIConstants.MEMBER_SERVICES.COMPLETE_PROFILE}`, ssoRegisterRequest, {observe: 'response'} ).pipe(take(1));
  }
}
