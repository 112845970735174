import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CompleteProfileService } from '../pre-complete-profile/complete-profile.service';
import { Router } from '@angular/router';
import { MemberService } from '../services/member/member.service';
import { emailPattern } from '../main/main.constants';
import { ContactInfo } from '../interfaces/contact-info.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppInsightsService } from '../services/app-insights/app-insights.service';
import { AppCenterAnalytics, AppCenterAnalyticsAPIMsgs } from '../services/app-insights/app-insights';
import { UserService } from '../services/user/user.service';
import { RequestRegisterSso } from '../interfaces/request/sso-register.interface';
import { Subscription } from 'rxjs';
const { v4: uuidv4 } = require('uuid');

@Component({
  selector: 'app-complete-profile',
  templateUrl: './complete-profile.component.html',
  styleUrls: ['./complete-profile.component.scss']
})
export class CompleteProfileComponent implements OnInit, OnDestroy {

  @ViewChild('error', { read: TemplateRef }) errorTemplate!: TemplateRef<any>;
  subscription: Subscription = new Subscription();
  userForm: UntypedFormGroup;
  isError = false;
  isLoading = false;
  mobileNumber = '';
  loaded = false;
  errorMsg!: string;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly router: Router,
    private readonly memberService: MemberService,
    private readonly snackBar: MatSnackBar,
    private readonly completeProfileService: CompleteProfileService,
    private readonly appInsightsService: AppInsightsService,
    private readonly userService: UserService
  ) {
    this.appInsightsService.trackPageViewWithoutClientId(`${AppCenterAnalytics.SSO_COMPLETE_PROFILE_PAGE_VIEWED}`);

    this.userForm = this.fb.group({
      email: [this.completeProfileService.user.email, [Validators.required, Validators.pattern(emailPattern)]],
      firstName: [this.completeProfileService.user.firstName, [Validators.required]],
      lastName: [this.completeProfileService.user.lastName, [Validators.required]],
      contactNumber: [this.mobileNumber, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      // mobileNumber: [this.completeProfileService.user.mobileNumber, [Validators.required,Validators.minLength(10), Validators.maxLength(10)]],
      dateOfBirth: [this.completeProfileService.user.dateOfBirth, [Validators.required]],
      cardId: [this.completeProfileService.user.cardId, [Validators.required]],
      clientId: [this.completeProfileService.user.clientId, [Validators.required]],
      personCode: [this.completeProfileService.user.personCode, [Validators.required]],
      // iAgreetotheTermsandConditions: [true, [Validators.required]],
      userName: [this.completeProfileService?.user?.userName, [Validators.required]],
      objectId: [''],
    });
  }

  ngOnInit(): void {
    this.getContactInfo();
  }

  private getContactInfo(): void {
    // for Orion users, fetch the contact information from the service
    if (this.userService.isOrionUser()) {
      this.loaded = true;
      if (this.completeProfileService.user.contactNumber) {
        this.mobileNumber = this.completeProfileService.user.contactNumber;
        this.userForm.patchValue({
          contactNumber: this.mobileNumber,
        });

        this.appInsightsService.trackPageViewWithoutClientId(`${AppCenterAnalytics.SSO_CONTACT_DETAILS_FETCHED}`, { data: { contactNumber: this.mobileNumber } });
      }

      if (!this.completeProfileService.user.userName) {
        // if username is not present, then generate the username using uuid
        const username = uuidv4();
        this.userForm.patchValue({
          userName: username,
        });
        this.appInsightsService.trackPageViewWithoutClientId(`${AppCenterAnalytics.SSO_USERNAME_GENERATED}`, { data: { userName: username } } );
      }
    } else {
      this.fetchSsoContactInfo();
    }
  }

  private fetchSsoContactInfo() {
    this.subscription.add(this.memberService.getContactSSOInfo().subscribe((data: ContactInfo) => {
      if (data && data.mobileNumber) {
        this.mobileNumber = data.mobileNumber;
        this.userForm.patchValue({
          contactNumber: data.mobileNumber,
        });
      }
      this.loaded = true;
    }, () => {
      this.loaded = true;
      this.showError('Failed to fetch the mobile number');
    }));
  }

  private showError(error: string) {
    this.errorMsg = error;
    this.snackBar.openFromTemplate(this.errorTemplate, {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'center'
    });
  }

  public complete(): void {
    this.appInsightsService.trackPageViewWithoutClientId(`${AppCenterAnalytics.SSO_COMPLETE_PROFILE_REGISTER_BUTTON_CLICKED}`);

    if (this.userService.isOrionUser()) {
      this.registerSsoMember();
    } else {
      this.updateSsoMemberDetail();
    }
  }

  private updateSsoMemberDetail() {
    this.isError = false;
    this.isLoading = true;

    this.subscription.add(this.memberService.UpdateSsoMemberDetail(this.userForm.value).subscribe(() => {
      this.isLoading = false;
      this.router.navigate(['/main']);
    }, () => {
      this.isError = true;
      this.isLoading = false;
    }));
  }

  private registerSsoMember() {
    this.isError = false;
    this.isLoading = true;

    const request: RequestRegisterSso = {
      username: this.userForm.controls.userName.value,
      clientId: this.userForm.controls.clientId.value,
      cardId: this.userForm.controls.cardId.value,
      firstName: this.userForm.controls.firstName.value,
      lastName: this.userForm.controls.lastName.value,
      birthdate: this.userForm.controls.dateOfBirth.value,
      email: this.userForm.controls.email.value,
      contactNumber: this.userForm.controls.contactNumber.value,
      agreeTermsAndConditions: true,
    }
    this.subscription.add(this.completeProfileService.registerSsoMember(request).subscribe((resp) => {
      this.isLoading = false;
      console.log('registerSsoMember: Success', resp);
      console.log('registerSsoMember Redirecting to: location', resp.headers.get('Location'));

      const responseText = AppCenterAnalyticsAPIMsgs.SSO_MEMBER_REGISTERED;
      this.appInsightsService.trackPageView(AppCenterAnalytics.SSO_COMPLETE_PROFILE_REGISTER_API_CALL, { data: request, Response: responseText });

      window.location.replace(resp.headers.get('Location'));
    }, (error) => {
      console.error('registerSsoMember: Error', error);
      this.appInsightsService.trackPageView(AppCenterAnalytics.SSO_COMPLETE_PROFILE_REGISTER_API_ERROR, { data: request, Response: error });

      this.isError = true;
      this.isLoading = false;
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
